import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SurveyService from 'services/survey.service';
import { CircularProgress, Box, Typography } from '@mui/material';

const SurveyHandler = () => {
    const { shortcode } = useParams();
    const [survey, setSurvey] = useState(null);
    const [loading, setLoading] = useState(true);
    const [SurveyThemeComponent, setSurveyThemeComponent] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSurveyData = async () => {
            try {
                const { data } = await SurveyService.surveyHandler(shortcode);
                setSurvey(data);

                const themeName = data.survey_theme?.name?.replace(/\s+/g, '') || 'LightTheme';
                const componentName = `survey${themeName}`;

                try {
                    const module = await import(`./${componentName}`);
                    setSurveyThemeComponent(() => module.default);
                } catch (error) {
                    console.error(`Error loading theme component: ${componentName}. Falling back to Light theme.`, error);
                    const fallbackModule = await import('./surveyLightTheme');
                    setSurveyThemeComponent(() => fallbackModule.default);
                }
            } catch (error) {
                console.error('Error fetching survey data:', error);
                if (error.code === 'error') {
                    navigate(error.redirect_to, {
                        state: {
                            code: error.code,
                            msg: error.msg
                        }
                    });
                }
            } finally {
                setLoading(false);
            }
        };

        fetchSurveyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shortcode]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!SurveyThemeComponent) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography color="error">Failed to load survey preview component.</Typography>
            </Box>
        );
    }

    return (
        <Suspense
            fallback={
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            }
        >
            <SurveyThemeComponent survey={survey} />
        </Suspense>
    );
};

export default SurveyHandler;
