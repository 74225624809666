/**
 * axios setup to use mock service
 */

import axios from 'axios';
import SnackbarUtils from './SnackbarUtils';

const axiosServices = axios.create();

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error.response);
        if (error.response?.status === 422) {
            // Get validation errors
            const errorData = error.response.data;
            if (error.response?.data?.msg) {
                SnackbarUtils.error(error.response?.data?.msg);
            } else if (errorData.errors) {
                const allErrors = Object.values(errorData.errors).flat().join(' | ');
                SnackbarUtils.error(allErrors);
            } else if (errorData.message) {
                SnackbarUtils.error(errorData.message);
            }
        }
        //  If the app is in maintenance mode
        if (error.response?.status === 503 && error.response?.data?.maintenance) {
            window.location.href = '/maintenance';
        }
        if (error.response.data.redirect) {
            // eslint-disable-next-line no-restricted-globals
            location.replace('/not-found');
        }
        return Promise.reject((error.response && error.response.data) || 'Wrong Services');
    }
);

export default axiosServices;
